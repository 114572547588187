<template>
  <div>
    <ed-simple-table disabledRegisterRows disabledSearchRows>
      <tbody>
        <tr>
          <td colspan="2"></td>
          <td
            style="background: #b1b5b4; color: #666666; font-weight: bold"
            :colspan="formData.riscoSeveridade.parametros.length"
          >
            SEVERIDADE
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td
            style="background: #eeeeee; color: #666666"
            v-for="(objSeveridade, index) in formData.riscoSeveridade.parametros"
            :key="index"
          >
            {{ objSeveridade.strCodigo }}. {{ objSeveridade.strNome }}
          </td>
        </tr>
        <tr>
          <td
            style="background: #b1b5b4; color: #666666; font-weight: bold"
            width="10"
            :rowspan="formData.riscoProbabilidade.parametros.length + 1"
          >
            PROBABILIDADE
          </td>
        </tr>
        <tr
          v-for="(objProbabilidade, index) in formData.riscoProbabilidade.parametros"
          :key="index"
          align="left"
        >
          <td style="background: #eeeeee !important; color: #666666" width="200">
            {{ objProbabilidade.strCodigo }}. {{ objProbabilidade.strNome }}
          </td>

          <td
            width="200"
            v-for="(objSeveridade, indexSeveridade) in formData.riscoSeveridade
              .parametros"
            :key="'severidade-' + indexSeveridade"
          >
            <ed-input-select
              class="col-12 pa-0 ma-2 mt-3"
              :key="'matrix-' + index + '-' + indexSeveridade"
              :name="'matrix-' + index + '-' + indexSeveridade"
              :background-color="
                getCor(
                  formData.riscoMatriz.strValor[objProbabilidade.intId][
                    objSeveridade.intId
                  ]
                )
              "
              hideDetails
              :outlined="false"
              v-model="
                formData.riscoMatriz.strValor[objProbabilidade.intId][objSeveridade.intId]
              "
              :items="formData.riscoClassificacaoSeveridade.parametros"
            />
          </td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import { EdInputSelect, EdSimpleTable } from "@/components/common/form";

import Parametro from "@/components/common/parametro/editar";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  components: {
    EdInputSelect,
    Parametro,
    EdSimpleTable,
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {
      strTab: "tab-probabilidade",
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        console.log("change");
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getCor(intParametroMatrizId) {
      if (!intParametroMatrizId) return "";

      let objParametroMatriz = this.formData.riscoClassificacaoSeveridade.parametros.find(
        (c) => c.intId == intParametroMatrizId
      );

      console.log("objParametroMatriz => " + intParametroMatrizId, objParametroMatriz);
      if (objParametroMatriz) {
        return objParametroMatriz.strValor.strCor;
      }
    },
  },
};
</script>
