<template>
  <div class="row mb-3">
    <matriz :intTipoDocumentoId="280" :key="$root.$session.versao" />
  </div>
</template>

<script>
import Matriz from "@/components/documento/pgr/matriz/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { Matriz },
  beforeDestroy() {},
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
