<template>
  <div
    class="col-12 text-left"
    v-if="bind.boolCarregado && !this.$root.$session.loadingContent"
  >
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab
        href="#tab-geral"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Matriz
        <i :class="$utilidade.getIcone('matriz') + ' fa-2x'"></i>
      </v-tab>
      <v-tab
        href="#tab-probabilidade"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Probabilidade do Risco
        <i :class="$utilidade.getIcone('probabilidade') + ' fa-2x'"></i>
      </v-tab>

      <v-tab
        href="#tab-severidade"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Severidade do Risco
        <i :class="$utilidade.getIcone('risco') + ' fa-2x'"></i>
      </v-tab>

      <v-tab
        href="#tab-classificacao"
        v-if="$root.$session.verificarPermissaoMenu('cadastro.risco.configuracao')"
      >
        Classificação da Severidade
        <i :class="$utilidade.getIcone('classificacao') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form :handlerSave="strTab == 'tab-geral' ? salvar : null" disableFixedSave>
      <geral
        :formData="formData"
        :key="'geral-' + $root.$session.versao"
        v-if="
          strTab == 'tab-geral' && formData.riscoMatriz && formData.riscoMatriz.strValor
        "
      />
    </ed-form>

    <parametro
      v-if="strTab == 'tab-probabilidade'"
      strChave="riscoProbabilidade"
      :key="'probabilidade-' + $root.$session.versao"
      @salvar="initialize"
      disableIcon
      disableColor
    />

    <parametro
      v-if="strTab == 'tab-severidade'"
      strChave="riscoSeveridade"
      :key="'severidade-' + $root.$session.versao"
      @salvar="initialize"
      disableIcon
      disableColor
    />

    <parametro
      v-if="strTab == 'tab-classificacao'"
      strChave="riscoClassificacaoSeveridade"
      :key="'matriz-' + $root.$session.versao"
      @salvar="initialize"
      disableIcon
    />
  </div>
</template>

<script>
import Parametro from "@/components/common/parametro/editar";
import Geral from "./partials/geral.vue";
import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intTipoDocumentoId: {
      type: [Number],
      required: true,
      default: null,
    },
  },
  components: {
    Geral,
    Parametro,
    EdForm,
  },
  provide() {
    return {
      bind: this.bind,
      formData: {
        riscoMatriz: null,
      },
    };
  },
  mounted() {
    this.$root.$dialog.loading(true);
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        boolCarregado: false,
      },
      strTab: "tab-geral",
      formData: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize(formData) {
      // if (formData && formData.strChave) {
      //   this.formData[formData.strChave] = Object.assign(formData, {});
      //   return;
      // }

      this.$root.$request
        .get("Common/Parametro", {
          arrayParametro: [
            "riscoProbabilidade",
            "riscoSeveridade",
            "riscoClassificacaoSeveridade",
            "riscoMatriz",
          ],
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            if (!objResponse.result.riscoMatriz.strValor) {
              objResponse.result.riscoMatriz.strValor = {};
            }

            objResponse.result.riscoProbabilidade.parametros.forEach(
              (objProbabilidade) => {
                objResponse.result.riscoSeveridade.parametros.forEach((objSeveridade) => {
                  if (!objResponse.result.riscoMatriz.strValor[objProbabilidade.intId]) {
                    objResponse.result.riscoMatriz.strValor[objProbabilidade.intId] = {};
                  }
                });
              }
            );

            this.formData = Object.assign(objResponse.result, {});
          }

          this.$root.$dialog.loading(false);
          this.bind.boolCarregado = true;
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      let formData = {};

      formData.riscoMatriz = _.cloneDeep(this.formData.riscoMatriz);

      this.$root.$request.post("Common/Parametro", formData).then((objResponse) => {
        this.$root.$dialog.loading(false);
      });
    },
  },
};
</script>
